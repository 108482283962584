import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/containers/HomePage.vue';
import AboutUsPage from './components/containers/AboutUsPage.vue';
import VehiclesPage from './components/containers/VehiclesPage.vue';
import CreditsPage from './components/containers/CreditsPage.vue'



const routes = [
  { path: '/', component: HomePage },
  { path: '/AboutUs', component: AboutUsPage },
  { path: '/Vehicles', component: VehiclesPage },
  { path: '/Credits', component: CreditsPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top:0}
    }
  }
});



export default router;