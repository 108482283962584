import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import i18n from './i18n.js';


const head = createHead();
const app = createApp(App);


app.use(head);
app.use(router);
app.use(i18n);


app.mount('#app');

