<template>
  <div id="localesGrid">
    <div class="localeContainer">
      <select v-model="selectedLanguage" @change="changeLocale" id="localeSelect">
        <option value="it">IT</option>
        <option value="en">EN</option>
        <option value="ru">RU</option>
      </select>
      <img :src="flagImages[selectedLanguage]" class="localeFlag">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: localStorage.getItem('locale') || 'en',
      flagImages: {
        it: require('../assets/destinations_flags/italy.png'),
        en: require('../assets/destinations_flags/england.png'),
        ru: require('../assets/destinations_flags/russia.png')
      }
    };
  },
  methods: {
    changeLocale() {
      localStorage.setItem('locale', this.selectedLanguage);
      window.location.reload();
    }
  }
};
</script>

