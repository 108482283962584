<template>
  <div class="spaceDiv"></div>
    <section id="aboutUsSection">
  <h2>Chi siamo</h2>
      
    <div id="aboutUsGrid">

       
      <img src="../../assets/Lago_di_Como_aboutUs.jpg" class="aboutUsImage" id="initialImage"> 
          <div>
            <p>Servizi 

            Airport Transfer 

            I nostri autisti esperti ti accompagneranno da e per gli aeroporti di Malpensa, Linate, Bergamo e Zurigo. Puntualità e comfort sono la nostra priorità. 

            Autista Privato 

            Hai bisogno di un autista professionale? Siamo qui per te. Raggiungi le tue destinazioni con stile e tranquillità. 

            Day Tour (Esperienze) 

            Lago di Como 

            Scoprite la bellezza senza tempo del Lago di Como con un tour personalizzato. 
            </p>
          <img src="../../assets/Lago_di_Como_aboutUs.jpg" class="aboutUsImage" id="secondImage"> 
          </div>
<p>
  Attraverso pittoreschi villaggi, ville storiche e panorami mozzafiato, immergetevi nella magia di questo luogo incantevole. Alcune delle tappe includono: 

            Bellagio: La “Perla del Lago”, famosa per i suoi giardini e le viste panoramiche. 

            Varenna: Un borgo medievale con strette stradine e romantici scorci sul lago. 

            Tremezzo: Visita alla Villa Carlotta e ai suoi giardini.
St. Moritz 

Esplorate la rinomata località di St. Moritz, situata nelle Alpi svizzere. Godetevi l'aria fresca di montagna, le viste spettacolari e le attività di lusso. Alcune esperienze da non perdere: 

Passeggiata sul lago ghiacciato: Camminate sul ghiaccio del Lago St. Moritz durante'inverno. 

Shopping di lusso: Scoprite boutique di alta moda e gioiellerie esclusive. 

Milano 

La vivace città di Milano offre una combinazione di storia, cultura e modernità. Il nostro autista vi accompagnerà attraverso le principali attrazioni: 

Duomo di Milano: Ammirate la maestosa cattedrale gotica e salite sulla terrazza per una vista panoramica. 

Galleria Vittorio Emanuele II: Fate shopping in questa elegante galleria commerciale. 

Teatro alla Scala: Scoprite il famoso teatro d'opera. 

Lago Maggiore 

Concedetevi una giornata rilassante sulle sponde del Lago Maggiore. Alcune attività da considerare: 

Isole Borromee: Visita alle isole di Isola Bella, Isola Madre e Isola dei Pescatori. 

Stresa: Esplorate questa graziosa cittadina con vista sulle montagne. 

Scegliete il vostro day tour preferito e lasciate che il nostro autista vi conduca in un viaggio indimenticabile. Contattateci per prenotare il vostro tour personalizzato! 

City Tour dei Laghi 

Scopri la magia dei laghi italiani con i nostri tour. Visita le pittoresche rive del Lago di Como, le acque cristalline del Lago di Lugano e l'eleganza del Lago Maggiore. 

Wine Tour 

Goditi un viaggio enogastronomico nelle rinomate regioni vinicole. Assapora i vini di Valtellina, immergiti nell'atmosfera di Barolo e scopri le bollicine di Franciacorta. 

Transfer e Servizio Navetta per Matrimoni ed Eventi Privati 

Organizziamo trasferimenti impeccabili per matrimoni, feste e altri eventi speciali. Rendi il tuo giorno ancora più speciale con il nostro servizio di noleggio con conducente. </p>
      </div>
    </section>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    setup() {
    useHead({
      title: 'Chi siamo - Top Transfers Lake Como',
      meta: [
        {
          name: 'description',
          content: 'Pagina del sito dove viene descritta la storia e i servizi dell\'azienda.'
        }
      ]
    })
  }
}
</script>