<template>
  <header>
    <h1 class="title">
      <span class="red">T</span>op <span class="red">T</span>ransfers <span class="red">L</span>ake <span class="red">C</span>omo
    </h1>

    <nav>
      <RouterLink to="/">{{ $t('home') }}</RouterLink>
      <RouterLink to="/AboutUs">{{ $t('chi_siamo') }}</RouterLink>
      <RouterLink to="/Vehicles">{{ $t('veicoli') }}</RouterLink>
      <RouterLink to="/Credits">{{ $t('crediti') }}</RouterLink>
      <!--<button @click="scroll">SCROLL</button>-->
    </nav>
  </header>
</template>

<script>


export default {
  methods: {
    scroll() {
      window.scrollTo({
        top: 4500,
        behavior: "smooth"
      });
    }
  },
  components: {
    
  },
}

</script>
