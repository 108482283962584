<template>
  <div class="reservationGrid" id="reservationGrid">
      <h3>{{ $t('prenotazioni') }}</h3>
      <div class="lineCenter">
      <hr>
    </div>

      <button @click="scrollTo" id="destinationsScroll">{{ $t('clicca') }} </button> 
    <form @submit.prevent="submitForm" id="reservationForm" ref="reservationForm">
     <div>
       <img src="../assets/icone_form/icona_casa.png" alt="icona casa" class="formIcon">
        <label for="pickupAddress">{{$t('ind_prelievo')}}</label>
       </div>
        
        <input type="text" placeholder="Es. Via Mario Rossi 11" required id="pickupAddress" name="pickupAddress" v-model="formData.going.pickUpAddress" v-if="!formData.going.returnButton || !returnCheck">
        <input type="text"  placeholder="Es. Via Mario Rossi 11" id="pickupAddressReturn" name="pickupAddressReturn" v-model="formData.returning.pickUpAddress" v-if="formData.going.returnButton && returnCheck">
      
       <div>
         <img src="../assets/icone_form/icona_palazzo.png" alt="icona palazzo" class="formIcon">

      <label for="dropoffAddress">{{ $t('ind_destinazione') }}</label>
       </div>

        <input type="text" placeholder="Es. Via Mario Rossi 11" required id="dropoffAddress" name="dropoffAddress"  v-model="formData.going.dropOffAdress" v-if="!formData.going.returnButton || !returnCheck">
        <input type="text" placeholder="Es. Via Mario Rossi 11" id="dropoffAddressReturn" name="dropoffAddressReturn"  v-model="formData.returning.dropOffAdress" v-if="formData.going.returnButton && returnCheck">
      
       <div>
        <img src="../assets/icone_form/icona_biglietti.png" alt="icona biglietti" class="formIcon">

      <label for="ticket">{{ $t('biglietto') }}</label>
     </div>
      
        <input type="text" id="ticket" name="ticket"  v-model="formData.going.ticket" v-if="!formData.going.returnButton || !returnCheck">
        <input type="text" id="ticketReturn" name="ticketReturn" v-model="formData.returning.ticket" v-if="formData.going.returnButton && returnCheck">
      
      <div>
     <img src="../assets/icone_form/icona_persona.png" alt="icona persona" class="formIcon">
      <label for="passengerCount">{{ $t('num_pas') }}</label>

     </div>
        <input type="number" required id="passengerCount" name="passengerCount" min="1"  v-model="formData.going.numberPassengers" v-if="!formData.going.returnButton || !returnCheck">
        <input type="number" id="passengerCountReturn" name="passengerCountReturn" min="1"  v-model="formData.returning.numberPassengers" v-if="formData.going.returnButton && returnCheck">
      
        <div>
         <img src="../assets/icone_form/icona_valigia.png" alt="icona valigia" class="formIcon">

      <label for="luggageCount">{{ $t('num_bag') }}</label>
     </div>
      
        <input type="number" required id="luggageCount" name="luggageCount" v-model="formData.going.luggageCount" v-if="!formData.going.returnButton || !returnCheck">
        <input type="number" id="luggageCountReturn" name="luggageCounReturn" v-model="formData.returning.luggageCount" v-if="formData.going.returnButton && returnCheck">
      
        <div>
       <img src="../assets/icone_form/icona_calendario.png" alt="icona calendario" class="formIcon">
      <label for="reservationDate">{{ $t('data') }}</label>
     </div>

        <input type="date" required id="reservationDate" name="reservationDate"  v-model="formData.going.date" v-if="!formData.going.returnButton || !returnCheck">
        <input type="date" id="reservationDateReturn" name="reservationDateReturn"  v-model="formData.returning.date" v-if="formData.going.returnButton && returnCheck">
      
        <div>
        <img src="../assets/icone_form/icona_orologio.png" alt="icona orologio" class="formIcon">
      <label for="reservationTime">{{ $t('ora') }}</label>
     </div>

        <input type="time" required id="reservationTime" name="reservationTime"  v-model="formData.going.time" v-if="!formData.going.returnButton || !returnCheck">
        <input type="time" id="reservationTimeReturn" name="reservationTimeReturn"  v-model="formData.returning.time" v-if="formData.going.returnButton && returnCheck">

        <div>
        <img src="../assets/icone_form/icona_ritorno.png" alt="icona ritorno" class="formIcon">
      <label for="returning">{{  $t('ritorno_prenota') }}</label>
     </div>

        <input v-model="returnCheck" name="returning" id="returning" type="checkbox">

    </form>
    <div class="reservationSwitchButton">

        <button class="placeHolder" v-if="!returnCheck">{{  $t('andata') }}</button>
      <button @click="changeToGoing" v-if="returnCheck" class="returnSelectionButton" id="reservationGoingButton">{{  $t('andata') }}</button>
   
    
      <button class="placeHolder" v-if="!returnCheck">{{  $t('ritorno') }}</button>
      <button @click="changeToReturning" v-if="returnCheck" class="returnSelectionButton" id="reservationReturningButton">{{  $t('ritorno') }}</button>
    

  </div>
    <button type="submit" form="reservationForm" @click="hideForm" id="submitFormButton">{{  $t('prenota') }}</button>
    </div>

</template>

<script>
 import axios from 'axios'
 import '../assets/styles/style.css';


 export default {
  data() {
    return {
      formData: {going: {pickUpAddress: '',
      dropOffAdress: '',
      ticket: '',
      numberPassengers: '',
      luggageCount: '',
      date: '',
      time: '',
      returnButton: false,
    }, 
    returning: {pickUpAddress: '',
      dropOffAdress: '',
      ticket: '',
      numberPassengers: '',
      luggageCount: '',
      date: '',
      time: '',
    }, 
    },
  returnCheck: false};
  },
  watch: {
    formData: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
  },
  methods: {
    submitForm() {
      if (this.isFormValid) {
      axios.post('http://localhost:3000/submitform', this.formData)
      .then(response => console.log(response)).catch(error => console.log(error));
      } else {
        alert('Please fill in all required fields before submitting.');
      }
    },
    validateForm() {
      this.isFormValid = this.$refs.reservationForm.checkValidity();
    },
    changeToGoing() {
     this.formData.going.returnButton = false;
     var reservationGoingButton = document.getElementById("reservationGoingButton")
     var reservationReturningButton = document.getElementById("reservationReturningButton")

     reservationGoingButton.style.backgroundColor = "#7fccff"
     reservationReturningButton.style.backgroundColor = "black"

     reservationGoingButton.style.color = 'black';
     reservationReturningButton.style.color = 'white';

     reservationGoingButton.style.width = '10em';
     reservationReturningButton.style.width = '5em';
     reservationGoingButton.style.height = '5em';
     reservationReturningButton.style.height = '3em';
    },
    changeToReturning() {
     this.formData.going.returnButton = true;
     var reservationReturningButton = document.getElementById("reservationReturningButton")
     var reservationGoingButton = document.getElementById("reservationGoingButton")

     reservationGoingButton.style.backgroundColor = "black"
      reservationReturningButton.style.backgroundColor = "#7fccff"
      
      reservationGoingButton.style.color = 'white';
     reservationReturningButton.style.color = 'black';

     reservationGoingButton.style.width = '5em';
     reservationReturningButton.style.width = '10em';
     reservationGoingButton.style.height = '3em';
     reservationReturningButton.style.height = '5em';
    },
    hideForm() {
      if (this.isFormValid) {
         document.getElementById('reservationGrid').style.display = 'none';
         document.getElementById('formSubstitute').style.display = 'block';
      }
     },
     scrollTo() {
      var destComu = document.getElementById('destinationsGrid');
      window.scrollTo({
                top: destComu.offsetTop,
                behavior: 'smooth' 
            });
     }
  },
};
</script>