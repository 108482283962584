<template>

        <h2 style="margin-top: 5vh;">{{ $t('destinazioni_com') }}</h2>

        <div id="destinationsGrid">
            <p>Ginevra</p>
            <img src="../../assets/destinations_flags/switzerland.jpg" class="flag" alt="bandiera italiana">
            <p>Zurigo</p>
            <img src="../../assets/destinations_flags/switzerland.jpg" class="flag" alt="bandiera svizzera">
            <p>Lucerna</p>
            <img src="../../assets/destinations_flags/switzerland.jpg" class="flag" alt="bandiera svizzera">
            <p>Lugano</p>
            <img src="../../assets/destinations_flags/switzerland.jpg" class="flag" alt="bandiera svizzera">
            <p>Milano</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Verona</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Venezia</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Bologna</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Firenze</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Pisa</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Roma</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Costiera amalfitana</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Forte dei marmi</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Portofino</p>
            <img src="../../assets/destinations_flags/italy.png" class="flag" alt="bandiera italiana">
            <p>Montecarlo</p>
            <img src="../../assets/destinations_flags/monaco.png" class="flag" alt="bandiera di monaco">
            <p>Nizza</p>
            <img src="../../assets/destinations_flags/france.jpg" class="flag" alt="bandiera francese">
            <p>Cannes</p>
            <img src="../../assets/destinations_flags/france.jpg" class="flag" alt="bandiera francese">
            <p>St. Tropez</p>
            <img src="../../assets/destinations_flags/france.jpg" class="flag" alt="bandiera francese">


        </div>

</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    setup() {
    useHead({
      title: 'Destinazioni - Top Transfers Lake Como',
      meta: [
        {
          name: 'description',
          content: 'Pagina del sito dove vengono illustrate le destinazioni possibili per il viaggio.'
        }
      ]
    })
  }
}
</script>