<template> 
  <body>
    <Locales></Locales>
   <div class="spaceDiv"></div>
 <section>
      <h2>{{ $t("Benvenuto") }}</h2>
      <div></div>
        <div id="homeSection">
          <article>
            <p>{{ $t('intro_pt.1') }}</p>
            <p>{{ $t('intro_pt.2') }}</p>
            <h1>{{ $t('servizi_disp') }}</h1>
    <ul>
      <li>{{ $t('servizi_1') }}</li>
      <li>{{ $t('servizi_2') }}</li>
      <li>{{ $t('servizi_3') }}</li>
      <li>{{ $t('servizi_4') }}</li>
      <li>{{ $t('servizi_5') }}</li>
      <li>{{ $t('servizi_6') }}</li>
      <li>{{ $t('servizi_7') }}</li>
      <li>{{ $t('servizi_8') }}</li>
      <li>{{ $t('servizi_9') }}</li>
    </ul>
            <p class="prenotaOra">{{ $t('prenota_ora') }}</p>
          </article>
          <div class="vertical-line"></div>
          <Form></Form>
        </div>
          <div id="reasonsGrid">
          <div id="leftReason">
            <img src="../../assets/rating.png" class="reasonIcon" alt="experience">
          <p>{{ $t('tit_motivo_1') }}</p>
          <p>{{ $t('motivo_1') }}</p>
          </div>

          <div>
          <img src="../../assets/lock.png" class="reasonIcon" alt="Privacy">
          <p>{{ $t('tit_motivo_2') }}</p>
          <p>{{ $t('motivo_2') }}</p>
          </div>

          <div id="rightReason">
            <img src="../../assets/energy.png" class="reasonIcon" alt="Availability" >
          <p>{{ $t('tit_motivo_3') }}</p>
          <p>{{ $t('motivo_3') }}</p>
          </div>
        </div>
        <Destinations></Destinations>
        <Contacts></Contacts>
    </section>
  </body>
 </template>
 
 <script>
 import Form from '../FormComponent.vue';
 import Contacts from '../ContactsComponent.vue'; 
 import Destinations from './DestinationsPage.vue';
 import Locales from '../LocalesComponent.vue';

 import { useHead } from '@vueuse/head';
 
 export default {
  components: {
    Form,
    Contacts,
    Locales,
    Destinations
  },
  setup() {
    useHead({
      title: 'Home - Top Transfers Lake Como',
      meta: [
        {
          name: 'description',
          content: 'Home page del sito con contatti dell\'azienda e form per le prenotazioni dei viaggi.'
        }
      ]
    })
  }
 };
 </script>
 