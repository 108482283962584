<template>
    <div class="spaceDiv">
        
    </div>
    
    <section>
        <h2>{{ $t('crediti_img') }}</h2>
        <div id="creditsGrid">
                <p>{{ $t('sfondo') }}</p>
                <p>Wikipedia Commons user: Benreis</p>
  
                <p>{{ $t('icona_casa') }}</p>
                <a href="https://www.flaticon.com/free-icons/home-button" title="home button icons">Home button icons created by Freepik - Flaticon</a>

                <p>{{ $t('icona_palazzo') }}</p>
                <a href="https://www.flaticon.com/free-icons/building" title="building icons">Building icons created by Freepik - Flaticon</a>         

                <p>{{ $t('icona_biglietti') }}</p>
                <a href="https://www.flaticon.com/free-icons/validating-ticket" title="validating ticket icons">Validating ticket icons created by Freepik - Flaticon</a>             

                <p>{{ $t('icona_persona') }}</p>
                <a href="https://www.flaticon.com/free-icons/user" title="user icons">User icons created by Freepik - Flaticon</a>

                <p>{{ $t('icona_bagaglio') }}</p>
                <a href="https://www.flaticon.com/free-icons/travel" title="travel icons">Travel icons created by Good Ware - Flaticon</a>

                <p>{{ $t('icona_calendario') }}</p>
                <a href="https://www.flaticon.com/free-icons/calendar" title="calendar icons">Calendar icons created by Freepik - Flaticon</a>

                <p>{{ $t('icona_orologio') }}</p>
                <a href="https://www.flaticon.com/free-icons/clock" title="clock icons">Clock icons created by Those Icons - Flaticon</a>

                <p>{{ $t('icona_ritorno') }}</p>
                <a href="https://www.flaticon.com/free-icons/return" title="return icons">Return icons created by Freepik - Flaticon</a>
                
                <p>lock</p>
                <a href="https://www.flaticon.com/free-icons/lock" title="lock icons">Lock icons created by Freepik - Flaticon</a>
                
                <p>Energy</p>
                <a href="https://www.flaticon.com/free-icons/efficiency" title="efficiency icons">Efficiency icons created by Freepik - Flaticon</a>

                <p>Experience</p>
                <a href="https://www.flaticon.com/free-icons/experience" title="experience icons">Experience icons created by Freepik - Flaticon</a>
              </div>
    </section>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    setup() {
    useHead({
      title: 'Crediti - Top Transfers Lake Como',
      meta: [
        {
          name: 'description',
          content: 'Pagina del sito dove vengono citate le fonti di provenienza delle immagini usate all\'interno del sito.'
        }
      ]
    })
  }
}
</script>