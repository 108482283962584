<template>
    <div class="spaceDiv"></div>
    <section>
        <h2>{{ $t('veicoli_disp') }}</h2>
        <div id="vehiclesGrid">
        <div id="pictureGrid">
          
          <p>Mercedes Classe E</p>
          <p>Mercedes Classe V</p>
          <p>Mercedes Classe S</p>

            <hr>
            <hr>
            <hr>

            <img src="../../assets/cars_pictures/classe_e.jpg" class="carPictures">
            <img src="../../assets/cars_pictures/classe_v.jpeg" class="carPictures"> 
            <img src="../../assets/cars_pictures/classe_s.jpg" class="carPictures"> 
            

            <p>{{ $t('classe_E') }}</p>
            <p>{{ $t('classe_V') }}</p>
            <p>{{ $t('classe_S') }}</p>
          </div>
          <p>{{ $t('veicoli_gruppi') }}</p>
        </div>
        
    </section>
</template>

<script>
import { useHead } from '@vueuse/head';


export default {
    setup() {
    useHead({
      title: 'Veicoli - Top Transfers Lake Como',
      meta: [
        {
          name: 'description',
          content: 'Pagina del sito che illustra i veicoli disponibili per i viaggi.'
        }
      ]
    })
  }
}
</script>