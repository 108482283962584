import IT from './locales/it.json';
import EN from './locales/en.json';
import RU from './locales/ru.json';

import { createI18n } from 'vue-i18n';

const i18n  = createI18n({
    locale: localStorage.getItem('locale'),
    messages: {
        it: IT,
        en: EN,
        ru: RU
    }
})


export default i18n;